<template>
  <div>
    <div class="mainpage-header">
      <div v-if="!modalOpen" style="vertical-align: baseline; float: left">
        <router-link to="/">
          <img class="app-logo" src="https://gcdn.bionluk.com/site/general/bionluk_logo_gk.png">
        </router-link>
      </div>
      <div v-if="modalOpen" class="modal-open-header">
        <p class="modal-open-header-title">{{modalInfo.modalInfo.title}}</p>
        <img @click="closeCardModal()" src="https://gcdn.bionluk.com/site/icon/tansel_mobile_menu_close_black.svg"/>
      </div>
      <div v-if="!user.uuid" style="float: right;">
        <div v-if="!openCloseLoginForm" @click="openCloseLoginForm = true">
          <img src="https://gcdn.bionluk.com/site/icon/tansel_mobile_menu_black.svg"/>
        </div>
        <div v-if="openCloseLoginForm" @click="openCloseLoginForm = false">
          <img src="https://gcdn.bionluk.com/site/icon/tansel_mobile_menu_close_black.svg"/>
        </div>
      </div>
    </div>
    <div class="settings" v-if="user.uuid || !openCloseLoginForm" style=" padding-top: 60px; min-width: 100vw">
      <div v-if="!modalOpen">
        <div class="forfreelancer-header">
          <div>
            <p class="header-title">
              İstediğin Gibi Çalış
            </p>
            <p class="header-description">
              Freelancer topluluğumuza katılarak, yeteneklerini sergilersin. Bionluk da sana iş bulur.
            </p>

              <router-link class="nounderlinehover" style="color: #FFF" to="/become_a_freelancer">
                <div class="header-button">
                  Freelancer Ol!
                </div>
              </router-link>

          </div>
        </div>
        <div class="header-bottom-div">
          <div class="header-info" v-for="(info,index) in headerInfos">
            <p class="header-info-title">{{info.title}}</p>
            <p class="header-info-info">{{info.info}}</p>
            <div class="header-info-seperator" v-if="index !== headerInfos.length - 1"></div>
          </div>
        </div>
        <div class="forfreelancer-cards-body">
          <div class="cards-main">
            <div class="card-div"
                 v-for="card in cardsArray"
                 @click="openCardModal(card)"
                 :style="`background: linear-gradient(rgba(0, 0, 0, 0.3),rgba(0, 0, 0, 0.3)),url(${card.imageUrl});   background-position: center; background-size: cover`">
              <div class="card-bottom">
                <p class="card-title">{{card.text}}</p>
                <p class="card-username">{{card.username}}</p>
                <img src="https://gcdn.bionluk.com/site/icon/tansel_freelancer_olmak_solok.svg"/>
              </div>
            </div>
            <div class="hiddendiv"></div>
            <div class="last-card">
              <p>Senin yeteneğin ne?</p>
              <div>
                <router-link class="nounderlinehover" style="color: #FFF" to="/become_a_freelancer">
                  Hemen Başla
                </router-link>
              </div>
            </div>
          </div>
          <div class="how-works-main">
            <p class="how-works-title">Nasıl çalışır?</p>
            <div style="display: flex; flex-direction: column; margin-top: 25px; margin-bottom: 25px;">
              <div class="work-desc-main" v-for="work in howWorksArray">
                <img :src="work.imageUrl"/>
                <p class="work-title">{{work.title}}</p>
                <p class="work-description">{{work.description}}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="slider-div">


          <p style="font-size: 24px;
      font-weight: 600;
      color: #333333;
      margin-top: 50px;">Kimlerle çalışabilirsin?</p>


          <div class="hiddendiv"></div>
          <div class="imageContainer">

            <img class="brand-image" style="margin-left: 20px"  src="https://gcdn.bionluk.com/site/general/freelancer_olmak/brands/isbankasi.png">
            <img class="brand-image" src="https://gcdn.bionluk.com/site/general/freelancer_olmak/brands/yemeksepeti.png">

            <img class="brand-image"  src="https://gcdn.bionluk.com/site/general/freelancer_olmak/brands/gures.png">
          </div>
          <div class="imageContainer">
            <img class="brand-image"  style="margin-left: 20px" src="https://gcdn.bionluk.com/site/general/freelancer_olmak/brands/trendyol.png">
            <img class="brand-image"  src="https://gcdn.bionluk.com/site/general/freelancer_olmak/brands/onedio.png">

            <img class="brand-image"  src="https://gcdn.bionluk.com/site/general/freelancer_olmak/brands/mutlubiev.png">

          </div>
          <div class="imageContainer">
            <img class="brand-image"  style="margin-left: 20px" src="https://gcdn.bionluk.com/site/general/freelancer_olmak/brands/sabancivakfi.png">
            <img class="brand-image" src="https://gcdn.bionluk.com/site/general/freelancer_olmak/brands/iyzico.png">

            <img class="brand-image"  src="https://gcdn.bionluk.com/site/general/freelancer_olmak/brands/oyveotesi.png">

          </div>
          <div class="imageContainer">
            <img class="brand-image"  style="margin-left: 20px" src="https://gcdn.bionluk.com/site/general/freelancer_olmak/brands/kollektifhouse.png">
            <img class="brand-image"  src="https://gcdn.bionluk.com/site/general/freelancer_olmak/brands/marmaray.png">

            <img class="brand-image"  src="https://gcdn.bionluk.com/site/general/freelancer_olmak/brands/insider.png">
          </div>

          <div class="hiddendiv10"></div>

          <div class="slider-body">
            <carousel :navigationEnabled="false" :perPage="1" class="slider-main">
              <slide v-for="(slider, index) in sliderData" :key="index" class="slider-inner">
                <p class="slider-user-text">"{{slider.text}}"</p>
                <img class="slider-user-image" :src="slider.imageUrl"/>
                <p class="slider-user-username">{{slider.username}}</p>
                <p class="slider-user-title">{{slider.title}}</p>
              </slide>
            </carousel>
          </div>
        </div>
        <div class="faq-body">
          <p class="faq-title">Sıkça sorulan sorular</p>
          <div class="faq-main">
            <div class="faq-array-div" v-for="faq in faqs">
              <div v-if="!faq.open" class="faq-close-div" @click="reverseFaq(faq)">
                <p>{{faq.question}}</p>
                <img src="https://gcdn.bionluk.com/site/icon/tansel_f_o_altok.svg"/>
              </div>
              <div v-if="faq.open" class="faq-open-div" @click="reverseFaq(faq)">
                <div style="display: flex; justify-content: space-between;">
                  <p class="faq-open-question">{{faq.question}}</p>
                  <img class="faq-open-image" src="https://gcdn.bionluk.com/site/icon/tansel_f_o_ustok.svg"/>
                </div>
                <p class="faq-open-answer">{{faq.answer}}</p>
              </div>
            </div>
          </div>
          <div class="faq-bottom">
            <p>Senin yeteneğin ne?</p>
            <div>
              <router-link class="nounderlinehover" style="color: #FFF" to="/become_a_freelancer">
                Hemen Başla
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div v-if="modalOpen">
        <div class="freelancers-modal-body">

          <div class="freelancers-modal-review-main">
            <div class="review-modal-image"
                 :style="`background: linear-gradient(rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0.5)),url(${modalInfo.modalInfo.modalImageUrl}); background-repeat: no-repeat; background-size:cover`">

              <div class="freelancer-modal-info">
                <p class="freelancer-modal-text">{{modalInfo.text}}</p>
                <div style="display: flex; margin-top: 26px;">
                  <p class="freelancer-modal-username">{{modalInfo.username}}</p>
                  <p class="freelancer-modal-title">{{modalInfo.title}}</p>
                </div>
              </div>
            </div>


            <div class="first-info-cell">
              <div class="info-a" v-for="info in modalInfo.modalInfo.info">
                <img class="info-a-image" :src="info.infoImage"/>
                <p class="info-a-title">{{info.title}}</p>
                <p class="info-a-data">{{info.data}}</p>
              </div>
            </div>
            <div class="freelancers-modal-info-bottom">
              <p class="info-bottom-title">Senin yeteneğin ne?</p>
              <div class="info-bottom-button">
                <router-link class="nounderlinehover" style="color: #FFF" to="/become_a_freelancer">
                  Hemen Başla
                </router-link>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
    <div v-if="!user.uuid && openCloseLoginForm" class="mainpage-mobile-login-form">

      <button class="super-button-light login-form-button-explore"
              @click="$router.push('/freelancer-bul')">Bionluk'u Keşfet
      </button>
      <div style="width: 120px; height: 2px;border-bottom: solid 1px #dde3eb; margin-top: 31px; margin-bottom: 29px;"></div>
      <button class="super-button-light login-form-button-freelancer"
              @click="$router.push('/register')">Freelancer Ol
      </button>
      <button class="super-button login-form-button-login"
              @click="$router.push('/login')">Giriş / Kaydol
      </button>
      <div style="width: 271px; height: 3px; border-bottom: solid 1px #dde3eb; margin-top: 85px;"></div>

      <div>
        <div style="margin-top: 20px; margin-bottom: 20px;display: flex;align-items: center">
          <div style="cursor: pointer;">
            <a href="https://facebook.com/bionluk" target="_blank">
              <img src="https://gcdn.bionluk.com/site/icon/tansel_mobile_facebook_gri.svg"/>
            </a>
          </div>

          <div style="cursor: pointer; margin-left: 30px;;">
            <a href="https://twitter.com/bionluk" target="_blank">
              <img src="https://gcdn.bionluk.com/site/icon/tansel_mobile_twitter_gri.svg"/>
            </a>
          </div>

          <div style="cursor: pointer; margin-left: 30px;">
            <a href="https://instagram.com/bionlukcom" target="_blank">
              <img src="https://gcdn.bionluk.com/site/icon/tansel_mobile_instagram_gri.svg"/>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {bindCodeToHtml, generateCodeForFaq} from "@/utils/schema";

  export default {
    name: "src-pages-body-main-for-freelancers-forFreelancersMobile",
    data() {
      return {
        oldScrollPosition: 0,
        cardsArray: [
          {
            imageUrl: 'https://gcdn.bionluk.com/site/general/freelancer_olmak/ozgur_k.jpg',
            username: 'ozgurbabal',
            text: 'Ben, profesyonel İngilizce/Türkçe seslendirme yaparım.',
            title: 'Seslendirmen',
            modalInfo: {
              title: 'Ses & Müzik',
              modalImageUrl: 'https://gcdn.bionluk.com/site/general/freelancer_olmak/ozgur_b.jpg',
              info: [
                {
                  infoImage: "https://gcdn.bionluk.com/site/icon/tansel_freelancer_olmak_1.svg",
                  title: "Tamamlanan Sipariş",
                  data: 132.532
                },
                {
                  infoImage: "https://gcdn.bionluk.com/site/icon/tansel_freelancer_olmak_2.svg",
                  title: "Farklı Müşteri",
                  data: 49.412
                },
                {
                  infoImage: "https://gcdn.bionluk.com/site/icon/tansel_freelancer_olmak_3.svg",
                  title: "Farklı Şehir",
                  data: 62
                },
                {
                  infoImage: "https://gcdn.bionluk.com/site/icon/tansel_freelancer_olmak_4.svg",
                  title: "Fiyat Aralığı",
                  data: "50TL - 13.500TL"
                }
              ]
            }

          },
          {
            imageUrl: 'https://gcdn.bionluk.com/site/general/freelancer_olmak/alina_k.jpg',
            username: 'alina_S',
            text: 'Ben, Rusça/Ukraynaca/İngilizce çevirilerinizi yapabilirim.',
            title: 'Çevirmen',
            modalInfo: {
              title: 'Yazı & Çeviri',
              modalImageUrl: 'https://gcdn.bionluk.com/site/general/freelancer_olmak/alina_b.jpg',
              info: [
                {
                  infoImage: "https://gcdn.bionluk.com/site/icon/tansel_freelancer_olmak_1.svg",
                  title: "Tamamlanan Sipariş",
                  data: 36.765
                },
                {
                  infoImage: "https://gcdn.bionluk.com/site/icon/tansel_freelancer_olmak_2.svg",
                  title: "Farklı Müşteri",
                  data: 52.342
                },
                {
                  infoImage: "https://gcdn.bionluk.com/site/icon/tansel_freelancer_olmak_3.svg",
                  title: "Farklı Şehir",
                  data: 64
                },
                {
                  infoImage: "https://gcdn.bionluk.com/site/icon/tansel_freelancer_olmak_4.svg",
                  title: "Fiyat Aralığı",
                  data: "10TL - 8.500TL"
                },
              ]
            }

          }
        ],
        howWorksArray: [
          {
            imageUrl: 'https://gcdn.bionluk.com/site/icon/tansel_f_o_3.png',
            title: 'İş ilanı oluştur',
            description: 'Uzman olduğun kategorilerde başvuru yaptıktan sonra sıra ilan oluşturmaya geldi. İlanın yayınlandığı andan itibaren satış yapmaya hazırsın!'
          },
          {
            imageUrl: 'https://gcdn.bionluk.com/site/icon/tansel_f_o_2.png',
            title: 'İşi teslim et',
            description: 'Artık alıcılar senden hizmet almaya başlayabilir. Aldığın siparişini tamamla, puan ve olumlu yorum toplamaya devam et. '
          },
          {
            imageUrl: 'https://gcdn.bionluk.com/site/icon/tansel_f_o_1.png',
            title: 'Ödemeni al',
            description: 'Teslim ettiğin sipariş onaylandıktan sonra ödemen hemen yolda. Bionluk’ta verdiğin hizmetin karşılığını her zaman zamanında alırsın.'
          },
        ],
        headerInfos: [
          {
            title: 'Her 6 Dakikada',
            info: '1 yeni sipariş'
          },
          {
            title: 'Tamamlanan Sipariş',
            info: '+450.000 adet'
          },
          {
            title: 'Fiyat Aralığı',
            info: '10TL - 22.600TL'
          },
        ],
        faqs: [
          {
            open: false,
            question: 'Bionluk’ta ne iş yapabilirim?',
            answer: 'Freelance çalışma sana yaratıcılık sunar. Bionluk\'ta freelancer olmak istiyorsan; yasalara ve kullanıcı sözleşmesine aykırı olmadığı sürece, çeviri, metin yazarlığı, grafik tasarım, web sitesi tasarımı gibi, 7 ana, 60\'dan fazla alt kategoride hizmet verebilir ve satış yapmaya başlayabilirsin.'
          },
          {
            open: false,
            question: 'Freelancer olarak ne kadar para kazanabilirim?',
            answer: 'Freelancer  olarak Yazı & Çeviri, Sosyal Medya Yönetimi, Logo Tasarımı gibi yetenekli olduğun farklı kategorilerde dilediğin kadar para kazanabilisin. Ne  kadar kazanacağın tamamen sana bağlı; daha çok sipariş ve yüksek puan aldıkça kazanma şansın da  artabilir.'
          },
          {
            open: false,
            question: 'Bionluk freelancer üyeliği ne kadar?',
            answer: 'Bionluk’ta tek seferliğe mahsus olmak üzere 69.90TL ödeyerek freelancer hesabı oluşturabilirsin. Topluluk üyelerine daha verimli bir alan sunmak üzere  Bionluk.com siparişlerin üzerinden %20 hizmet bedeli alır, toplam tutarın %80’i ise sana kalır. Yani sipariş alıp para kazanana kadar üyelik ücreti dışında herhangi bir ödeme yapmana gerek kalmaz.'
          },
          {
            open: false,
            question: 'Tam zamanlı işim varken de freelance olarak çalışabilir miyim?',
            answer: 'Freelancer olmak genellikle "ek iş" gibi görülse de pazaryeri dinamiklerini anladıktan ve müşteri portföyünü oluşturduktan sonra "tam zamanlı bir freelancer" olarak çalışabilir ve ne zaman, nasıl çalışacağına tamamen sen karar verebilirsin.'
          },
          {
            open: false,
            question: 'Hizmet ücretleri nasıl belirleniyor?',
            answer: 'Bionluk’ta hizmetlerinin bedelini sen belirlersin. Yine de kafan karışıyorsa hizmet verdiğin kategoride diğer ilanların fiyatlandırmasını kontrol etmeni öneririz.'
          },
          {
            open: false,
            question: 'Bionluk’ta ilan fiyatları nasıl belirlenir?',
            answer: 'Bionluk\'ta satış yapmak için fiyatlarını tamamen sen belirlersin. Yine de kafan karışıyorsa hizmet verdiğin kategoride diğer ilanların fiyatlandırmasını kontrol etmeni öneriyoruz.'
          },
          {
            open: false,
            question: 'Bionluk’ta freelancer olarak ödememi nasıl alırım?',
            answer: 'Siparişini teslim ettikten sonra alıcı işi onaylar ve ödemen anında Bionluk Bakiyene aktarılır. Bakiyenden para çekme talimatı verdiğinde, 2-5 iş günü içinde sipariş tutarı Bionluk\'da kayıtlı IBAN numarana aktarılır.'
          }
        ],
        sliderData: [
          {
            imageUrl: 'https://gcdn.bionluk.com/site/general/freelancer_olmak/testi_1.jpg',
            text: 'Volt için hazırladığımız video serisinin seslendirmesini nasıl yaptıracağımızı konuşurken biri Bionluk\'u önerdi. Örnek seslendirmeleri dinledik, istediğimiz bir sesi bulduk, metinleri paylaştık ve bir gün sonra ses kayıtlarını aldık. :)',
            username: 'Seda Taner',
            title: 'Volt Turkey, Marketing Manager'
          },
          {
            imageUrl: 'https://gcdn.bionluk.com/site/general/freelancer_olmak/testi_2.jpg',
            text: 'Yuvako.com olarak hızlı ve yetenekli freelancer arayışımızda Bionluk gibi bir platformun varlığı bize çok yardımcı oldu. İçimize sinen güzel bir proje ortaya çıkardık. Teşekkürler Bionluk!',
            username: 'Can Erenberk',
            title: 'Co-founder, Yuvako.com'
          },
          {
            imageUrl: 'https://gcdn.bionluk.com/site/general/freelancer_olmak/testi_4.jpg',
            text: 'Garajyeri olarak araç sahipleri için sistemi anlatan videolar hazırlamamız gerekiyordu. Bionluk üzerinden metinleri teslim ettik. 1 hafta sonra videolar sitemizde yayındaydı. Çok temiz iş. Hizmet verenlerin iletişimi mükemmel.',
            username: 'Arda Aşkın',
            title: 'Kurucu, Garajyeri.com'
          },
          {
            imageUrl: 'https://gcdn.bionluk.com/site/general/freelancer_olmak/testi_5.jpg',
            text: 'Acil olarak beliren bir seslendirme projemiz için arayışımız vardı. O noktada Bionluk.com’a göz atarak, uygun bütçeyle bir seslendirme sanatçısı bulduk. Hem bütçe hem hız anlamında çok iyi sonuç aldık. Çok teşekkürler Bionluk.com!',
            username: 'Çağdaş Önen',
            title: 'Pazarlama Müdürü, iyzico'
          },
          {
            imageUrl: 'https://gcdn.bionluk.com/site/general/freelancer_olmak/testi_3.jpg',
            text: 'Ucuz olduğu için Bionluk`ta alışveriş yapmıyorduk. Ama ekstra seçeneklerle almak istediğimiz kalitede bir hizmet alabileceğimizi farkettik. Bionluk`ta ihtiyacımız olan işleri kolay ve hızlı bir şekilde yaptırabiliyoruz',
            username: 'Güneş Gülbiter',
            title: 'Sosyal Medya Direktörü, Scorp App'
          },

        ],
        openCloseLoginForm: false,
        modalOpen: false,
        modalInfo: null
      }
    },

    methods: {
      reverseFaq(faq) {
        faq.open = !faq.open;
      },

      openCardModal(card) {
        this.modalInfo = card;
        this.modalOpen = true;
        this.oldScrollPosition = window.scrollY;
        window.scrollTo(0,0);
      },

      closeCardModal() {
        this.modalOpen = false;
        setTimeout(()=> {
          window.scrollTo(0,this.oldScrollPosition);
        },0)
      }
    },
    created() {
      bindCodeToHtml(JSON.stringify(generateCodeForFaq(this.faqs)))
    }
  }
</script>

<style scoped lang="scss">
  .mainpage-header {
    padding-top: 20px;
    padding-right: 15px;
    padding-left: 13px;
  }

  .app-logo {
    height: 28px;
    margin-top: 2px;
  }

  /*login part*/
  .mainpage-mobile-login-form {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;
    width: 100vw;
    height: 90vh
  }

  .mainpage-mobile-login-form {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;
    width: 100vw;
    height: 90vh
  }

  .login-form-button-explore {
    width: 270px;
    height: 50px;
    border-radius: 2px;
    border: solid 1px #bfc8d2;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    color: #2d3640;
  }

  .login-form-button-freelancer {
    width: 270px;
    height: 50px;
    border-radius: 2px;
    border: solid 1px #2d3640;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    color: #2d3640;
  }

  .login-form-button-login {
    width: 270px;
    height: 50px;
    border-radius: 2px;
    background-color: #2d3640;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    color: #ffffff;
    margin-top: 10px;
  }

  /*header*/

  .forfreelancer-header {
    margin: 0 auto;
    height: 300px;
    text-align: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .header-title {
      font-size: 32px;
      font-weight: 500;
      text-align: center;
      color: #2d3640;
    }
    .header-description {
      margin: 10px 20px;
      font-weight: 400;
      font-size: 18px;
      line-height: 1.44;
      text-align: center;
      color: #2d3640;
    }

    .header-button {
      width: 193px;
      height: 50px;
      margin: 0 auto;
      margin-top: 40px;
      border-radius: 2px;
      background-color: #fd4056;
      font-size: 16px;
      font-weight: 600;
      text-align: center;
      color: #ffffff;
      line-height: 50px;
      cursor: pointer;
    }
  }

  .header-bottom-div {
    width: 100%;
    background-color: #2d3640;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 26px;
  }

  .header-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    .header-info-title {
      font-size: 18px;
      text-align: center;
      color: #ffffff;
      opacity: 0.7;
    }

    .header-info-info {
      font-size: 24px;
      font-weight: 600;
      text-align: center;
      color: #ffffff;
      margin-top: 6px;
    }
  }

  .header-info-seperator {
    width: 50px;
    height: 1px;
    opacity: 0.4;
    background-color: #ffffff;
    margin-top: 26px;
    margin-bottom: 29px;
  }

  .forfreelancer-cards-body {
    min-width: 100vw;
    padding-top: 30px;
    background-color: #f4f5f7;
    display: flex;
    flex-direction: column;
    align-items: center;

  }

  .cards-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
  }

  .card-div {
    width: 265px;
    height: 340px;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    background-position: center;
    background-size: 265px 340px;
    display: flex;
    cursor: pointer;
    margin-top: 10px;
    &:first-child {
      margin-top: 0;
    }
    .card-bottom {
      margin-top: auto;
      padding-left: 18px;

      .card-title {
        font-size: 20px;
        font-weight: 500;
        line-height: 1.2;
        color: #ffffff;
        width: 228.7px;
      }

      .card-username {
        margin-top: 11px;
        font-size: 13px;
        line-height: 1.85;
        color: #ffffff;
      }

      img {
        margin-top: 13px;
        width: 23.7px;
        height: 19px;
        margin-bottom: 14px;
      }
    }
  }

  .last-card {
    width: 265px;
    height: 340px;
    margin-top: 10px;
    background: url('https://gcdn.bionluk.com/site/general/tansel_freelancer_bg4.png');
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      width: 162px;
      font-size: 22px;
      font-weight: 600;
      line-height: 1.27;
      text-align: center;
      color: #333333;
    }

    div {
      margin-top: 12px;
      width: 200px;
      height: 50px;
      border-radius: 2px;
      background-color: #fd4056;
      font-size: 16px;
      font-weight: 500;
      text-align: center;
      color: #ffffff;
      line-height: 50px;
      cursor: pointer;
    }
  }

  .how-works-main {
    margin-top: 62px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 100vw;
    background-color: #fff;
    .how-works-title {
      font-size: 24px;
      font-weight: 600;
      color: #333333;
      margin-top: 44px;
    }
  }

  .work-desc-main {
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    align-items: center;
    &:first-child {
      margin-top: 0;
    }

    img {
      width: 89px;
      height: 89px;
    }
    .work-title {
      margin-top: 3px;
      font-size: 20px;
      font-weight: 600;
      text-align: center;
      color: #2d3640;
    }

    .work-description {
      margin-top: 10px;
      width: 270px;
      overflow: hidden;
      font-size: 16px;
      line-height: 1.5;
      text-align: center;
      color: #8b95a1;
    }

  }

  .slider-div {
    min-width: 100vw;
    background-color: #f4f5f7;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 60px;
  }

  .slider-body {
    margin-top: 15px;
    max-width: 945px;
    width: 90vw;
    height: 435px;
    background-color: #ffffff;
    border: solid 1px #f4f5f7;
    padding-top: 20px;
    -webkit-box-shadow: 0 35px 30px -30px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 35px 30px -30px rgba(0, 0, 0, 0.3);
    box-shadow: 0 35px 30px -30px rgba(0, 0, 0, 0.3);
    .slider-main {
      max-width: 945px;
      width: 90vw;
      margin: 0 auto;
    }
  }

  .slider-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 945px;
    width: 90vw;
    margin-bottom: 30px;
    .slider-user-image {
      margin-top: 30px;
      width: 80px;
      height: 80px;
      border-radius: 50%;
    }

    .slider-user-text {
      width: calc(90vw - 20px);
      max-width: 610px;
      max-height: 250px;
      overflow-y: scroll;
      font-style: italic;

      color: #2d3640;
      opacity: 0.8;
      font-size: 17px;
      font-weight: normal;
      font-style: italic;
      font-stretch: normal;
      line-height: 1.55;
      letter-spacing: normal;
      text-align: center;

    }

    .slider-user-username {
      margin-top: 17px;
      font-size: 16px;
      font-weight: 500;
      text-align: center;
      color: #2d3640;
    }

    .slider-user-title {
      margin-top: 7px;
      font-size: 12px;
      text-align: left;
      color: #8b95a1;
    }

  }

  .faq-body {
    min-width: 100vw;
    padding-bottom: 31px;
    padding-top: 31px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;

    .faq-title {
      font-size: 26px;
      font-weight: 600;
      text-align: center;
      color: #333333;
    }
  }

  .faq-main {
    display: flex;
    flex-direction: column;
    margin-top: 43px;
    .faq-array-div {
      width: 100vw;
    }
  }

  .faq-close-div {
    border-top: solid 1px #e0e0e0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 15px 17px 14px 20px;
    cursor: pointer;
    p {
      font-size: 16px;
      text-align: left;
      color: #2d3640;
    }
  }

  .faq-open-div {
    border-top: solid 1px #e0e0e0;
    padding: 25px 17px 35px 20px;
    background-color: #f1f2f4;
    cursor: pointer;
    .faq-open-question {
      font-size: 16px;
      font-weight: 600;
      text-align: left;
      color: #2d3640;
    }

    .faq-open-answer {
      font-size: 14px;
      line-height: 1.43;
      text-align: left;
      color: #8b95a1;
      margin-top: 16px;
    }
  }

  .faq-bottom {
    margin-top: 10px;
    border-top: solid 1px #e0e0e0;
    width: 100vw;
    height: 201px;
    background: url('https://gcdn.bionluk.com/site/icon/freelancer_olmak_bg_alt.png');
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      font-size: 24px;
      font-weight: 600;
      text-align: center;
      color: #2d3640;
      width: 230px;
    }

    div {
      width: 200px;
      height: 50px;
      margin-top: 30px;
      border-radius: 2px;
      background-color: #fd4056;
      font-size: 16px;
      font-weight: 600;
      text-align: center;
      color: #ffffff;
      line-height: 50px;
      cursor: pointer;
    }
  }

  /*modal*/

  .modal-open-header {
    width: 100%;
    vertical-align: baseline;
    float: left;
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      width: 16px;
      height: 16px;
    }

    .modal-open-header-title {
      font-size: 20px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #333333;
    }
  }

  .freelancers-modal-body {
    display: flex;
    flex-direction: column;
    overflow: auto;
    width: 100vw;
    height: 100%;
  }

  .freelancers-modal-review-main {
    width: 100vw;

    .review-modal-image {
      width: 100vw;
      height: 100vw;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
  }

  .freelancer-modal-info {
    align-self: flex-end;
    padding: 0 15px 29px 15px;
    margin-left: auto;
    margin-right: auto;
    .freelancer-modal-text {
      font-size: 18px;
      font-weight: normal;
      font-style: italic;
      font-stretch: normal;
      line-height: 1.22;
      letter-spacing: normal;
      text-align: left;
      color: #ffffff;
    }

    .freelancer-modal-username {
      font-size: 16px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #ffffff;
    }

    .freelancer-modal-title {
      font-size: 12px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #ffffff;
      align-self: flex-end;
      margin-left: 10px;
    }
  }

  .info-title-div {
    padding-top: 24px;
    padding-bottom: 21px;
    border-bottom: solid 2px rgba(151, 151, 151, 0.2);
  }

  .freelancers-modal-info-title {
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    color: #333333;

  }

  .first-info-cell {
    width: 100vw;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .info-a {
      width: calc(50vw - 1px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-bottom: solid 2px rgba(151, 151, 151, 0.2);
      &:nth-child(2n) {
        border-left: solid 2px rgba(151, 151, 151, 0.2);
      }
      .info-a-image {
        margin-top: 24px;
        width: 42px;
        height: 40px;
      }

      .info-a-title {
        opacity: 0.7;
        font-size: 16px;
        font-weight: 300;
        text-align: left;
        color: #333333;
        margin-top: 16px;
      }

      .info-a-data {
        font-size: 20px;
        font-weight: 600;
        text-align: center;
        color: #333333;
        margin-top: 11px;
        margin-bottom: 16px;
      }
    }
  }

  .freelancers-modal-info-bottom {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .info-bottom-title {
    margin-top: 19px;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.4;
    text-align: center;
    color: #333333;

  }

  .info-bottom-button {
    margin-top: 11px;
    margin-bottom: 25px;
    cursor: pointer;
    width: 240px;
    height: 50px;
    border-radius: 4px;
    background-color: #fd4056;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    color: #ffffff;
    line-height: 50px;
  }

  .brand-image{
    width: 72px; height: 72px; margin-right: 20px; border-radius: 6px; border: 1px solid #e1e1e1;
  }

  .imageContainer{
    display: flex; margin-top: 12px; margin-bottom: 12px; align-self: center; align-items: center; justify-content: center; text-align: center;
  }
</style>
